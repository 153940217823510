<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ salesDelivery.code }}</h3>
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">{{ $t("SALES_DELIVERIES.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ $timeZoneDateFormat(salesDelivery.created_at, "LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">
          {{ $t("SALES_DELIVERIES.EXPIRATION_TIME") }}
        </dt>
        <dd class="col-sm-8">
          {{ $timeZoneDateFormat(salesDelivery.expiration_time, "LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="salesDelivery.allowedLocations" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="salesDelivery.organization" />
        </dd>
      </dl>

      <dl class="row" v-if="salesDelivery.salesOrder">
        <dt class="col-sm-4">{{ $t("COMMON.SALES_ORDER") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="salesDelivery.salesOrder" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.RECIPIENT") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="salesDelivery.recipient" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
        <dd class="col-sm-8">
          <sales-delivery-status-badge :salesDelivery="salesDelivery" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4" v-if="salesDelivery.excerpt">
          {{ $t("COMMON.NOTE") }}
        </dt>
        <dd class="col-sm-8" v-if="salesDelivery.excerpt">
          <div v-html="salesDelivery.excerpt"></div>
        </dd>
      </dl>

      <div class="col-12 text-center mb-5 mt-5">
        <h2>{{ $t("COMMON.DELIVERY_ADDRESS") }}</h2>
      </div>

      <dl
        class="row"
        v-if="
          salesDelivery.delivery_entity_type === BILLING_ENTITY_TYPE_INDIVIDUAL
        "
      >
        <dt class="col-sm-4">{{ $t("COMMON.LASTNAME") }}</dt>
        <dd class="col-sm-8">
          {{ salesDelivery.delivery_lastname }}
        </dd>
      </dl>

      <dl
        class="row"
        v-if="
          salesDelivery.delivery_entity_type === BILLING_ENTITY_TYPE_INDIVIDUAL
        "
      >
        <dt class="col-sm-4">{{ $t("COMMON.FIRSTNAME") }}</dt>
        <dd class="col-sm-8">
          {{ salesDelivery.delivery_firstname }}
        </dd>
      </dl>

      <dl
        class="row"
        v-if="
          salesDelivery.delivery_entity_type === BILLING_ENTITY_TYPE_COMPANY
        "
      >
        <dt class="col-sm-4">{{ $t("COMMON.COMPANY_NAME") }}</dt>
        <dd class="col-sm-8">
          {{ salesDelivery.delivery_company_name }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
        <dd class="col-sm-8">
          {{ salesDelivery.delivery_country }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
        <dd class="col-sm-8">
          {{ salesDelivery.delivery_state }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
        <dd class="col-sm-8">
          {{ salesDelivery.delivery_city }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
        <dd class="col-sm-8">
          {{ salesDelivery.delivery_zipcode }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
        <dd class="col-sm-8">
          {{ salesDelivery.delivery_address }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
        <dd class="col-sm-8">
          {{ salesDelivery.delivery_email }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import SalesDeliveryStatusBadge from "./SalesDeliveryStatusBadge.vue";

export default {
  name: "sales-delivery-view-global",

  components: {
    SalesDeliveryStatusBadge,
  },

  props: ["salesDelivery"],

  data() {
    return {
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    salesDelivery(salesDelivery) {},
  },
};
</script>
