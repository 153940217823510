<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!salesDelivery">
      <span class="loader"></span>
    </span>
    <sales-delivery-form
      v-if="salesDelivery"
      :loading="loading"
      :salesDeliveryData="salesDelivery"
      :formErrors="formErrors"
      @salesDeliveriesubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import SalesDeliveryForm from "../partials/SalesDeliveryForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  layout: "DashboardLayout",

  components: { SalesDeliveryForm },

  mixins: [alertLeave, requestErrorMixin],

  props: {
    salesDeliveryId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      salesDelivery: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("salesDeliveries/get", this.salesDeliveryId);
        this.salesDelivery =
          this.$store.getters["salesDeliveries/salesDelivery"];
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(salesDelivery) {
      this.loading = true;
      const salesDeliveryData = cloneDeep(salesDelivery);
      try {
        await this.$store.dispatch("salesDeliveries/update", salesDeliveryData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("SALES_DELIVERIES.SALES_DELIVERY_UPDATED"),
        });
        const salesDelivery = await this.$store.getters[
          "salesDeliveries/salesDelivery"
        ];
        this.$emit("onViewSalesDelivery", salesDelivery, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
